/* General styles for the main app */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

input[type="ref"]{
  padding: 10px;
  margin: 10px;
  width: 200px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"] {
  padding: 10px;
  margin: 10px;
  width: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="ref"]:focus, .App input[type="text"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.25);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 300px;
  margin: 20px auto;
}

li {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  margin: 5px 0;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

li:hover {
  background-color: #f0f0f0;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

div {
  margin: 20px 0;
}

.save-status {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

/* Specific styles for location inputs */
.location-part {
  width: 30px;
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.location-part:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.25);
}

/* Styles for the login page */
.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.login-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.login-container input[type="text"], .login-container input[type="password"] {
  padding: 10px;
  margin: 10px 0;
  width: calc(100% - 22px);
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-container input[type="text"]:focus, .login-container input[type="password"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px 0 rgba(0, 123, 255, 0.25);
}

.login-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0056b3;
}
